import React from "react";
import Nav from "../components/Nav"
import Tos from "../components/Tos"
import Footer from "../components/Footer"

function ToS(props) {
  return (
      <div>
      <Nav />
      <Tos />
      <Footer />
    </div>			
  );
}

export default ToS;