import React from "react";
import "./style.css";

function Footer(props) {
  return (
    <div>
      <nav className="navbar footer bottom text-center">
          <span className=" mb-0 title">Coding Jobs 2020</span>
      </nav>
    </div>
  );
}

export default Footer;